import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import * as yup from 'yup';

import UserContext from "../userContext.js"

import Countdown from "react-countdown";

import Footer from "../components/footer.jsx";

import LogoSlider from "../components/logoSlider.jsx";

import { Link } from "gatsby";

import UserTopbar from "../components/userTopbar";

import ilustration4 from '../images/ilustration-4.png'

import range from '../images/range.svg'
import mt2 from '../images/my_troop_2.png'
import mt3 from '../images/my_troop_3.png'

import d1 from '../images/desafios/2desafio.svg'
import d2 from '../images/desafios/3desafio.svg'
import d3 from '../images/desafios/4desafio.svg'
import d4 from '../images/desafios/5desafio.svg'
import d5 from '../images/desafios/6desafio.svg'
import d6 from '../images/desafios/7desafio.svg'
import d7 from '../images/desafios/8desafio.svg'
import d8 from '../images/desafios/9desafio.svg'
import d9 from '../images/desafios/10desafio.svg'
import d10 from '../images/desafios/11desafio.svg'
import d11 from '../images/desafios/11desafio.svg'
import d12 from '../images/desafios/12desafio.svg'


import ClosedSelectableList from "../components/ClosedselectableList/index.jsx";

import filter from '../images/filter_icon.png'


import '../scss/challenges.scss'
import 'semantic-ui-css/semantic.min.css'
import Selectable from "../components/selectable/index.jsx";
import SwitchButton from "../components/switchButton/index.jsx";

export default class feed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: false,
    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidMount() {
    this.setState({
      client: true,
    })
  }

  render() {
    return (
      <>
        {this.state.client &&
          <>
            <div className="challenges" >
              <UserContext.Consumer>
                {userObject => (
                  <>
                    <UserTopbar user={userObject} profilePic={userObject?.userObject?.picture || null} />
                    <Helmet>
                    </Helmet>
                    <div className="wrapper app">

                      <img onDragStart={(e) => e.preventDefault()} className="range" src={range} />

                      <div className="linear--header">
                        <h3>
                          Desafíos
                        </h3>
                        <p>
                          Completá los desafíos para conseguir insignias y puntos
                          y subir tu rango en TROOP.
                        </p>
                      </div>

                      <ClosedSelectableList selectablesList={['Generales', 'CSGO', 'Valorant']} onClick={() => { }} />

                      <section className="challenges--section">
                        <div className="wrapper app">
                          <div className="challenges--row">
                            <img onDragStart={(e) => e.preventDefault()} src={d1} />
                            <img onDragStart={(e) => e.preventDefault()} src={d2} />
                            <img onDragStart={(e) => e.preventDefault()} src={d3} />
                          </div>
                          <div className="challenges--row">
                            <img onDragStart={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()} src={d4} />
                            <img onDragStart={(e) => e.preventDefault()} src={d5} />
                            <img onDragStart={(e) => e.preventDefault()} src={d6} />
                          </div>
                          <div className="challenges--row">
                            <img onDragStart={(e) => e.preventDefault()} src={d7} />
                            <img onDragStart={(e) => e.preventDefault()} src={d8} />
                            <img onDragStart={(e) => e.preventDefault()} src={d9} />
                          </div>
                          <div className="challenges--row">
                            <img onDragStart={(e) => e.preventDefault()} src={d10} />
                            <img onDragStart={(e) => e.preventDefault()} src={d11} />
                            <img onDragStart={(e) => e.preventDefault()} src={d12} />
                          </div>
                        </div>
                      </section>


                    </div>
                  </>
                )}
              </UserContext.Consumer>
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}
